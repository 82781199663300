<template>
  <div class="row">
    <div class="col-12">
      <router-link to="/legal">Legal</router-link>
      <h1>Terms of Service</h1>
      <h5>Effective May 25, 2018</h5>

      <p>Thanks for using Tjing.</p>
      <p>
        Please read these Terms carefully. By using Tjing or signing up for an
        account, you’re agreeing to these Terms. This is a legal agreement.
        We’ll start with the basics, including a few definitions that should
        help you understand this agreement. Tjing (“Tjing” or the “Service”) is
        a digital scoring service offered through the URL www.tjing.se (we’ll
        refer to it as the “Website”) that allows you to, among other things,
        create and manage certain events and tournaments, including, without
        limitation, scorecards and livescoring. Tjing is owned and operated by
        Kidpilot AB (“Tjing,” “we,” or “us”). As a customer of the Service or a
        representative of an entity that’s a customer of the Service, you’re a
        “Member” according to this agreement (or “you”).
      </p>
      <p>
        These Terms of Use (“Terms,” including our Privacy Policy) define the
        terms and conditions under which you’re allowed to use Tjing, and how
        we’ll treat your account while you’re a Member. If you have any
        questions about our terms, feel free to contact us.
      </p>

      <h5>Terms of Service</h5>
      <p>
        The Term begins when you sign up for Tjing and continues as long as you
        use the Service. Clicking the button and entering your first and last
        name means that you’ve officially “signed” the Terms. If you don’t agree
        to these Terms, you must immediately discontinue your use of the
        Service.
      </p>

      <h5>Registration and Accounts</h5>
      <p>
        To use the Service, you must register a Tjing account. You may however
        register for a specific event as a ”Guest” without creating a Tjing
        account. If you do so, you agree to comply with these Terms and any
        Tjing policies made available to you on the Services. You agree to: (a)
        provide true, accurate, current and complete information about yourself
        (“Member Data”) and (b) maintain and promptly update the Member Data.
        You agree that Tjing may use your Member Data to provide Services for
        which you have expressed interest and as otherwise set forth in these
        Terms.
      </p>

      <p>
        You’re responsible for keeping your password confidential. You’ll
        immediately notify us of any unauthorized use of your account. We’re not
        responsible for any losses due to stolen or hacked passwords. We don’t
        have access to your current password, and for security reasons, we may
        only reset your password.
      </p>

      <p>
        The Service are intended for persons who are 13 years old or older. If
        you are under the legal age to form a binding contract in your
        jurisdiction, you represent that your legal guardian has reviewed and
        agreed to these Terms.
      </p>

      <h5>Termination</h5>
      <p>
        You or Tjing may terminate this agreement at any time by and for any
        reason by giving Notice to the other party. We may suspend our Service
        to you at any time, with or without cause. Once terminated, we may
        permanently delete your account and all the data associated with it,
        including your account and activities. Results and place on leaderboards
        cannot be reinstated. You may request deletion of your personally
        identifiable information by contacting us at hello@tjing.se. If you
        don’t log in to your account for 24 or more months, we may treat your
        account as “inactive” and permanently delete the account and all the
        data associated with it.
      </p>

      <h5>Content</h5>
      <p>
        You represent and warrant that you either own or have permission to use
        all the information you make available in connection with the Services
        (“Content”). You grant us a non-exclusive, transferable, sublicensable,
        royalty-free, worldwide license to use any Content that you post on or
        in connection with the Services. This license ends when you delete your
        Content or your account unless your Content has been shared with others,
        and they have not deleted it.
      </p>

      <h5>Changes</h5>
      <p>
        We may change any of the Terms by posting revised Terms of Use on our
        Website and/or by sending an email to the last email address you gave
        us. Unless you terminate your account within ten (10) days, the new
        Terms will be effective immediately and apply to any continued or new
        use of Tjing. We may change the Website, the Service, or any features of
        the Service at any time.
      </p>

      <h5>General Rules</h5>
      <p>
        You promise to not post any statement, image, photograph, advertisement,
        or other content that in our sole judgment could be reasonably perceived
        to harm, threaten, promote the harassment of, promote the intimidation
        of, promote the abuse of, or promote discrimination against others based
        solely on race, ethnicity, national origin, sexual orientation, gender,
        gender identity, religious affiliation, age, disability, disease, or
        immigration status.
      </p>
      <p>
        If you violate any of these rules, then we may suspend or terminate your
        account.
      </p>

      <h5>Third parties</h5>
      <p>
        Tjing or third parties may provide links to other internet sites or
        resources through the Services. Tjing does not endorse and is not
        responsible or liable for any content, advertising, products or other
        materials on or available from such sites or resources. You acknowledge
        and agree that Tjing is not responsible for the availability of such
        external sites or resources.
      </p>

      <h5>Limitation of Liability</h5>
      <p>
        To the maximum extent permitted by law, you assume full responsibility
        for any loss that results from your use of the Website and the Services,
        including any downloads from the Website. We won’t be liable for any
        indirect, punitive, special, or consequential damages under any
        circumstances, even if they’re based on negligence or we’ve been advised
        of the possibility of those damages.
      </p>

      <h5>No warranties</h5>
      <p>
        To the maximum extent permitted by law, we provide the material on the
        Website and the Service as is. That means we don’t provide warranties of
        any kind, either express or implied, including but not limited to
        warranties of merchantability and fitness for a particular purpose.
      </p>
      <p>
        You expressly agree that Tjing does not assume responsibility for the
        inspection, supervision, preparation, or conduct of any contest,
        challenge, event, or group activity that utilises the services,
        including any that are organised by an administrator.
      </p>

      <h5>Indemnity</h5>
      <p>
        You agree to indemnify and hold us harmless from any losses, including
        attorney fees that result from any claims you make that aren’t allowed
        under these Terms due to a “Limitation of Liability” or other provision.
        (Indemnity is an agreement to compensate someone for a loss.) You also
        agree to indemnify and hold us harmless from any losses, including
        attorney fees, that result from third-party claims that you or someone
        using your password did something that, if true, would violate any of
        these Terms.
      </p>

      <h5>Proprietary Rights</h5>
      <p>
        You will respect our proprietary rights in the Website and the software
        used to provide Tjing (proprietary rights include patents, trademarks,
        service marks, trade secrets, and copyrights)
      </p>
      <p>
        You represent and warrant that you either own or have permission to use
        all of the material and retain ownership of the materials that you
        upload to the Service. We may use or disclose your materials only as
        described in these Terms and our Privacy Policy.
      </p>

      <h5>Disclaimers</h5>
      <p>
        We aren’t responsible for the behavior of any advertisers, linked
        websites, or other Members.
      </p>

      <h5>Reporting Abuse</h5>
      <p>
        If you think anyone is violating any of these Terms, please notify us
        immediately.
      </p>

      <h5>Entire Agreement</h5>
      <p>
        These Terms and our Privacy Policy (all of which are incorporated into
        these Terms by reference), and any additional terms you’ve agreed to by
        turning on specific features (“Additional Terms”) make up the entire
        agreement and supersede all prior agreements, representations, and
        understandings. Any Additional Terms will be considered incorporated
        into these Terms when you activate the feature.
      </p>
      <p>
        Where there’s a conflict between these Terms and the Additional Terms,
        the Additional Terms will control.
      </p>
      <p>
        Congratulations! You’ve reached the end. Thanks for taking the time to
        learn about Tjing’s policies.
      </p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "TermsOfService",
};
</script>
<style lang="scss" scoped >
p {
  color: $elephant;
  margin-bottom: 15px;
  @include Gilroy-Medium;
}
h1 {
  margin-top: 10px;
  display: block;
}
h5 {
  margin-bottom: 20px;
}
a {
  display: block;
  margin-top: 26px;
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}
@media (min-width: 992px) {
  a {
    display: block;
    margin-top: 40px;
  }
}
</style>
